import React from "react";
import { BlogPostData } from "../../templates/blog-post";
import { PageWithSidebar } from "../layout/PageWithSidebar";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { Link } from "gatsby";

interface BlogDetailProps {
  data: BlogPostData;
}

export const BlogDetail: React.FC<BlogDetailProps> = ({
  data: { contentHtml, description, title, tableOfContentsHtml },
}) => {
  deckDeckGoHighlightElement();
  return (
    <PageWithSidebar
      sidebarComponent={
        <>
          <Link to="/blog" className="button">
            Zpět na aktuality
          </Link>
          <div className="sidebar-header">Obsah stránky:</div>
          <div dangerouslySetInnerHTML={{ __html: tableOfContentsHtml }} />
        </>
      }
    >
      <div className="markdown-content">
        <div className="markdown-heading">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </div>
    </PageWithSidebar>
  );
};
