import React from "react";

interface PageWithSidebarProps {
  sidebarComponent: React.ReactNode;
}

export const PageWithSidebar: React.FC<PageWithSidebarProps> = ({ sidebarComponent, children }) => {
  return (
    <div className="container-narrow">
      <div className="layout-sidebar">
        <div className="sidebar">
          <div className="sidebar-content">{sidebarComponent}</div>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
