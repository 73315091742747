import React from "react";
import { graphql } from "gatsby";
import { LandingSmall } from "../components/Landing";
import { BlogDetail } from "../components/blog/BlogDetail";
import { PageLayout } from "../components/PageLayout";
import { Helmet } from "react-helmet";

export interface BlogPostData {
  title: string;
  description: string;
  contentHtml: string;
  tableOfContentsHtml: string;
}

const BlogPostPage = ({ data }) => {
  const { frontmatter, html, tableOfContents } = data.markdownRemark;
  const blogPostData: BlogPostData = {
    title: frontmatter.title,
    description: frontmatter.description,
    contentHtml: html,
    tableOfContentsHtml: tableOfContents,
  };

  return (
    <PageLayout>
      <Helmet>
        <title>{blogPostData.title}</title>
      </Helmet>
      <LandingSmall name={blogPostData.title === "jak zacit doma" ? "jak začít doma" : "aktuality"} />
      <BlogDetail data={blogPostData} />
    </PageLayout>
  );
};

export default BlogPostPage;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 40) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
      tableOfContents(maxDepth: 3)
    }
  }
`;
